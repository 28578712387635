import {
  CarouselFeatureClass,
  globalCss,
  MotifComponent,
} from '@snapchat/snap-design-system-marketing';

// TODO: remove once we have integrated mask-image styles into the motif framework
// https://jira.sc-corp.net/browse/WEBP-11462
export const snapCarouselMaskCss = globalCss`
  /* Apply custom style when carouel items overflow container. */
  .${MotifComponent.CAROUSEL}.${CarouselFeatureClass.HasOverflow} > div {
    mask-image: linear-gradient(to left, transparent, black 10%);
    html[dir='rtl'] & {
      mask-image: linear-gradient(to right, transparent, black 10%);
    }
  }
`;
